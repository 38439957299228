import { describe, it, expect } from "vitest";
import { mount } from "@vue/test-utils";
import { createTestingPinia } from "@pinia/testing";

import SawadeContentLogos from "./SawadeContentLogos.vue";

describe("SawadeContentLogos", () => {
  it("SawadeContentLogos mounts correctly", () => {
    const component = mount(SawadeContentLogos, {
      props: {},
      global: {
        plugins: [createTestingPinia()],
      },
    });
    expect(component).toBeTruthy();
  });
});
